import { message } from 'antd'
import axios, { AxiosError } from 'axios'
import dayjs, { unix } from 'dayjs'

import { ConversationDetails } from '@/hooks/useConversation'

import { DocumentTag, MAX_IMPORT_SIZE } from '@/constants/admin'
import { configFileHeader, configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { commands } from '@/utils'

import { Agent, Swarm } from '@/types'
import {
  IGeneratedType,
  IMessage,
  IQuestion,
  IQuestionPost,
} from '@/types/chatbot'

let stopFlag = false

export function getValue(value: string) {
  return value.replace('<!>metadata:', '').replace('<!>', '')
}

// A scroller function that takes element id and smooth scrolls to it.
export const scrollToBottom = (containerId: string) => {
  const container = document.getElementById(containerId)
  if (container) {
    container.scrollTo({
      top: container.scrollHeight,
      behavior: 'smooth',
    })
  }
}

export function isCommand(message: string) {
  return commands.some((c) => message?.startsWith(c.command + ' '))
}

export function mapConversation(
  details: ConversationDetails[],
  agents: Agent[]
): IQuestion[] {
  const messages: IQuestion[] = []

  let tmpQuestion: IQuestion | undefined = undefined

  for (const detail of details) {
    tmpQuestion = {
      question: detail.question,
      messages: [
        {
          role: 'user',
          type: 'text',
          domain: detail.domain,
          message: detail.question,
          drafts: [],
          documents: [],
          titles: [],
          googleDriveUrls: [],
          timestamp: unix(detail.timestamp / 1000).format('HH:mm / DD.MM.YYYY'),
          isCommand: isCommand(detail.question),
          feedbackScore: detail.feedbackScore,
        },
      ],
    }

    const agent = agents.find((agent) => agent.id === detail.agent)
    if (agent && detail.answer) {
      tmpQuestion.messages.push({
        role: 'assistant',
        type: agent.model === 'dalle' ? 'image_url' : 'text',
        domain: detail.domain,
        message: detail.answer.replace(/<function>.*?<\/function>/g, ''),
        agent: detail.agent,
        drafts: [],
        documents: detail.documents,
        titles: [],
        googleDriveUrls: [],
        timestamp: unix(detail.timestamp / 1000).format('HH:mm / DD.MM.YYYY'),
        isCommand: isCommand(detail.question),
        logId: detail.logId,
        feedbackScore: detail.feedbackScore,
      })
      // check if this last pushed message has any tools commands
      parseToolsCommands(
        detail.answer,
        tmpQuestion.messages[tmpQuestion.messages.length - 1] as IMessage
      )
    }

    if (tmpQuestion) {
      messages.push(tmpQuestion)
    }
  }

  return messages
}

export const getContext = (questions: IQuestion[]) => {
  const speakers: string[] = []
  const messagesArray: IQuestionPost[] = []
  if (questions.length === 0) {
    return { messages: [], speakers: [] }
  }

  for (const question of questions) {
    for (const message of question.messages) {
      if (message.message === '' || message.message === 'NO ANSWER') continue

      if (message.role === 'assistant' && message.agent) {
        speakers.push(message.agent)
      } else {
        speakers.push('user')
      }

      const text = message.type === 'text' ? message.message : undefined
      const image =
        message.type === 'image_url' ? { url: message.message } : undefined

      messagesArray.push({
        role: message.role,
        content: [
          {
            type: message.type,
            text: text,
            image_url: image,
          },
        ],
      })
    }
  }
  return { messages: messagesArray.splice(-30), speakers: speakers.splice(-30) }
}

export const getAnswerDraft = async (
  domain: boolean,
  question: string,
  temperature: number,
  questions: IQuestion[],
  agentRequest: string,
  swarm?: Swarm,
  useGpt4?: boolean,
  adminChatbot?: boolean,
  user?: string,
  currentConversationId?: string
) => {
  try {
    const { messages, speakers } = getContext(questions)

    const config = {
      method: 'post',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        question,
        domain,
        useGpt4,
        adminChatbot: !!adminChatbot,
        messages,
        temperature,
        agent: agentRequest,
        swarm: swarm?.id,
        speakers,
        user,
        conversationId: currentConversationId,
      }),
    }

    const res = await axios(`${API_URL}/v2/chatbot/question`, config)

    return res.data
  } catch (error) {
    console.error(error)
  }
}

function parseMetadata(
  value: string,
  tmpMessage: IMessage,
  conversationId: string | undefined,
  agents: Agent[]
) {
  const data = JSON.parse(value)
  tmpMessage.logId = data.log
  tmpMessage.documents = data.documents
  tmpMessage.titles = data.titles
  tmpMessage.googleDriveUrls = data.googleDriveUrls
  tmpMessage.agent = data.agent
  tmpMessage.role = 'assistant'
  if (data.conversationId) {
    conversationId = data.conversationId
  }
  if (data.audio) {
    tmpMessage.audio = data.audio
  }
  const agent = agents.find((agent) => agent.id === tmpMessage.agent)
  if (agent?.model === 'dalle') {
    tmpMessage.type = 'image_url'
  } else {
    tmpMessage.type = 'text'
  }
  return conversationId
}

// Tool command is defined like:
// <function><name>${fnName}</name><arguments>${args}</arguments></function>
// There can be multiple tools commands in one message.
function parseToolsCommands(value: string, tmpMessage: IMessage) {
  const regex =
    /<function><name>.*?<\/name><arguments>.*?<\/arguments><\/function>/g
  const matches = value.match(regex)
  if (matches) {
    for (const match of matches) {
      const fnName = match.match(/<name>(.*?)<\/name>/)
      const args = match.match(/<arguments>(.*?)<\/arguments>/)

      if (fnName && args && fnName[1] === 'uiComponents' && args[1]) {
        const arg = JSON.parse(args[1]) as IGeneratedType
        if (arg.type === 'button' && arg.button) {
          tmpMessage.button = {
            text: arg.button.text,
          }
        } else if (arg.type === 'form' && arg.form) {
          tmpMessage.form = {
            items: arg.form.items,
          }
        } else if (arg.type === 'radioGroup' && arg.radio_group) {
          tmpMessage.radio_group = {
            items: arg.radio_group.items,
          }
        } else if (arg.type === 'checkboxGroup' && arg.checkbox_group) {
          tmpMessage.checkbox_group = {
            items: arg.checkbox_group.items,
          }
        }
      }
    }
  }
}

function isMetadata(value: string) {
  const regex = /<!>metadata:.*?<!>/g
  const matches = value.match(regex)
  return matches || []
}

export const getAnswer = async (
  domain: boolean,
  question: string,
  selectedAgent: string,
  questions: IQuestion[],
  agents: Agent[],
  setLoading: (value: boolean) => void,
  setQuestions: (value: IQuestion[]) => void,
  t: (value: string) => string,
  isCommand: boolean,
  user?: string,
  useGpt4?: boolean,
  currentConversationId?: string,
  streaming?: boolean,
  drafting?: boolean,
  adminChatbot?: boolean,
  swarm?: Swarm
) => {
  stopFlag = false

  const tmpQuestions = [...questions]
  let conversationId: string | undefined = undefined
  let resolved = true

  // user input message
  const tmpQuestion: IQuestion = {
    question: question,
    messages: [
      {
        role: 'user',
        type: 'text',
        domain: domain,
        message: question,
        agent: selectedAgent,
        drafts: [],
        documents: [],
        titles: [],
        googleDriveUrls: [],
        timestamp: dayjs().format('HH:mm / DD.MM.YYYY'),
        isCommand: isCommand,
        feedbackScore: 0,
      },
    ],
  }

  tmpQuestions.push(tmpQuestion)

  // repeat until all agents have nothing else to say
  while (!stopFlag) {
    // start loading and disable input
    setLoading(true)

    // prepare template for assistant's response
    const tmpMessage: IMessage = {
      role: 'assistant',
      domain: domain,
      message: '',
      type: 'text',
      timestamp: dayjs().format('HH:mm / DD.MM.YYYY'),
      documents: [],
      titles: [],
      googleDriveUrls: [],
      drafts: [],
      isCommand: isCommand,
      feedbackScore: 0,
    }
    tmpQuestion.messages.push(tmpMessage)

    try {
      const drafts = []
      if (drafting && swarm === undefined) {
        drafts.push(
          getAnswerDraft(
            domain,
            question,
            0,
            tmpQuestions,
            selectedAgent,
            swarm,
            useGpt4,
            adminChatbot,
            undefined
          )
        )
        drafts.push(
          getAnswerDraft(
            domain,
            question,
            0.5,
            tmpQuestions,
            selectedAgent,
            swarm,
            useGpt4,
            adminChatbot,
            undefined
          )
        )
      }

      if (!streaming) {
        drafts.unshift(
          getAnswerDraft(
            domain,
            question,
            1,
            tmpQuestions,
            selectedAgent,
            swarm,
            useGpt4,
            adminChatbot,
            user,
            currentConversationId
          )
        )
      } else {
        const { messages, speakers } = getContext(tmpQuestions)
        const response = await fetch(`${API_URL}/v2/chatbot/question-stream`, {
          method: 'post',
          ...configHeader,
          credentials: 'include',
          body: JSON.stringify({
            question,
            domain,
            useGpt4,
            adminChatbot: !!adminChatbot,
            messages,
            agent: selectedAgent,
            swarm: swarm?.id,
            speakers,
            user,
            conversationId: currentConversationId,
          }),
        })

        const reader = response.body
          ?.pipeThrough(new TextDecoderStream())
          .getReader()

        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (!reader || stopFlag) break
          const { value, done } = await reader.read()
          if (done) break

          // parse tools commands
          parseToolsCommands(value, tmpMessage)

          const isMetadataArray = isMetadata(value)
          if (isMetadataArray.length > 0) {
            // parse all received metadata chunks
            for (const metadata of isMetadataArray) {
              conversationId = parseMetadata(
                getValue(metadata),
                tmpMessage,
                conversationId,
                agents
              )
            }
            // check if there is any text beside metadata
            const text = value
              .replace(/<!>metadata:.*?<!>/g, '')
              .replace(/<function>.*?<\/function>/g, '')
            tmpMessage.message += text
          } else {
            if (value.startsWith('{"resolved":false,')) {
              tmpMessage.type = 'text'
              tmpMessage.message = t('unknown-error')
              stopFlag = true
              resolved = false
              break
            } else {
              tmpMessage.message += value.replace(
                /<function>.*?<\/function>/g,
                ''
              )
            }
          }

          setQuestions([...tmpQuestions])
        }
        tmpMessage.drafts.push(tmpMessage.message)
      }

      if (drafts.length > 0) {
        const draftResponses = await Promise.all(drafts)
        const agent = agents.find((agent) => agent.id === tmpMessage.agent)
        if (!streaming) {
          if (agent?.model === 'dalle') {
            tmpMessage.type = 'image_url'
          } else {
            tmpMessage.type = 'text'
          }
          if (draftResponses[0].conversationId) {
            conversationId = draftResponses[0].conversationId
          }
          parseToolsCommands(draftResponses[0].answer, tmpMessage)

          tmpMessage.message = draftResponses[0].answer.replace(
            /<function>.*?<\/function>/g,
            ''
          )
          tmpMessage.documents = draftResponses[0].documents
          tmpMessage.titles = draftResponses[0].titles
          tmpMessage.agent = draftResponses[0].agent
          tmpMessage.audio = draftResponses[0].audio
          resolved = draftResponses[0].resolved
        }

        tmpMessage.drafts.push(
          ...draftResponses.map((draft) =>
            draft.answer.replace(/<function>.*?<\/function>/g, '')
          )
        )
        setQuestions([...tmpQuestions])
      }
    } catch (error) {
      console.error(error)
      stopFlag = true
    } finally {
      if (
        tmpMessage.message === '' ||
        tmpMessage.message === 'NO ANSWER' ||
        swarm === undefined
      ) {
        stopFlag = true
      }

      // if command and resolved are true, show toast message
      if (isCommand) {
        if (resolved) {
          message.success(t('toast-command-success'))
        } else {
          message.error(t('toast-command-failure'))
        }
      }

      // stop loading and enable input
      setLoading(false)

      // focus input
      setTimeout(() => {
        const input = document.getElementById('chat-input')
        input?.focus()
      }, 50)
    }
  }
}

export const stopAnswering = async (
  streaming: boolean | undefined,
  setLoading: (value: boolean) => void
) => {
  if (streaming) {
    stopFlag = true
  }
  // else {
  //   console.log('not implemented')
  // }
  setLoading(false)
}

export async function uploadDocuments(
  fileList: FileList | null,
  agentId: string | undefined,
  t: (value: string) => string,
  setFileList: (value: FileList | null) => void,
  importToUserStorage: boolean = true,
  tag?: DocumentTag
) {
  if (!fileList || !agentId) {
    return
  }

  message.loading(t('toast-import-importing'), 0)
  const files = fileList ? Array.from(fileList) : []

  const totalImportSize = files.reduce((acc, file) => {
    return acc + file.size / 1024
  }, 0)

  if (totalImportSize > MAX_IMPORT_SIZE) {
    message.error(t('toast-import-failure-max-size'))
    return
  }

  const formDataCsv = new FormData()
  const formDataPdf = new FormData()
  const formDataDocx = new FormData()
  const formDataXlsx = new FormData()

  for (const file of files) {
    if (file.type === 'text/csv') {
      formDataCsv.append('file', file, file.name)
    } else if (file.type === 'application/pdf') {
      formDataPdf.append('file', file, file.name)
    } else if (
      file.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      formDataDocx.append('file', file, file.name)
    } else if (
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      formDataXlsx.append('file', file, file.name)
    }
  }

  const config = {
    url: `${API_URL}/v2/knowledge-base/document/upload`,
    method: 'post',
    withCredentials: true,
    ...configFileHeader,
  }

  try {
    if (formDataCsv.getAll('file').length > 0) {
      const configCsv = {
        ...config,
        data: formDataCsv,
        params: { type: 'CSV', importToUserStorage, agentId, tag },
      }
      await axios(configCsv)
    }
    if (formDataPdf.getAll('file').length > 0) {
      const configPDF = {
        ...config,
        data: formDataPdf,
        params: { type: 'PDF', importToUserStorage, agentId, tag },
      }
      await axios(configPDF)
    }
    if (formDataDocx.getAll('file').length > 0) {
      const configDOCX = {
        ...config,
        data: formDataDocx,
        params: { type: 'DOCX', importToUserStorage, agentId, tag },
      }
      await axios(configDOCX)
    }
    if (formDataXlsx.getAll('file').length > 0) {
      const configXLSX = {
        ...config,
        data: formDataXlsx,
        params: { type: 'XLSX', importToUserStorage, agentId, tag },
      }
      await axios(configXLSX)
    }
    setFileList(null)
    message.success(t('toast-import-success'))
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.data?.message === 'Auth failed.') {
        message.error(t('permissions-error-login'))
      } else {
        message.error(t('toast-import-failure'))
      }
    } else {
      message.error(t('toast-import-failure'))
    }

    console.error(error)
  }
}
